export const raptorize = {
    init: function () {

        $('.raptorize').raptorize({
            'enterOn' : 'click', //timer, konami-code, click
            'delayTime' : 5000 //time before raptor attacks on timer mode
        });

    },
};
