import axios from 'axios';

const openModal = (modalEl, downloadTitle) => {
    if (modalEl) {
        const formTitleEl = modalEl.querySelector('form .section-title');
        formTitleEl.innerText = downloadTitle ? `Download “${downloadTitle}”` : 'Download';

        modalEl.setAttribute('aria-hidden', 'false');
    }
}

const closeModal = (modalEl) => {
    if (modalEl) {
        const formTitleEl = modalEl.querySelector('form .section-title');
        formTitleEl.innerText = 'Download';

        modalEl.setAttribute('aria-hidden', 'true');
    }
}


const initPressResources = () => {
    const pressMediaEl = document.querySelector('#press-resources');

    if (!pressMediaEl) {
        return false;
    }

    const modalEl = document.querySelector('#press-modal')
    const pressCloseModalEl = document.querySelector('#press-close-modal');
    const pressItemBackdrop = modalEl.querySelector('.press-modal__backdrop');
    const formContainerEl = modalEl.querySelector('.press-modal__form');
    const formEl = modalEl.querySelector('form');
    const formErrorEl = modalEl.querySelector('#form-error');
    let currentDownloadUrl = '';

    if (pressMediaEl) {
        const pressItemEls = pressMediaEl.querySelectorAll('.press-resource');

        pressItemEls.forEach(el => {
            el.addEventListener('click', (evt) => {
                const itemTitleEl = el.querySelector('.press-resource__title');
                const itemTitle = itemTitleEl ? itemTitleEl.innerText : '';
                const isDownloadFormCompleted = localStorage.getItem("download-form-completed") === 'true';

                currentDownloadUrl = el.dataset.href || '';

                if (!isDownloadFormCompleted) {
                    $("#Download_item").val(currentDownloadUrl);
                    openModal(modalEl, itemTitle);
                } else if (currentDownloadUrl) {
                    window.open(currentDownloadUrl, '_blank');
                }
            })
        });

        // Click on backdrop
        pressItemBackdrop.addEventListener('click', () => {
            closeModal(modalEl);
        });
    }

    if (pressCloseModalEl) {
        pressCloseModalEl.addEventListener('click', () => {
            closeModal(modalEl);
        });
    }

    if (formEl) {
        formEl.addEventListener('submit', (evt) => {
            evt.preventDefault();

            const form = evt.currentTarget;
            const url = form.action.value;
            const formData = new FormData(form);

            if (url) {
                formErrorEl.innerHTML = '';

                axios({
                    method: "post",
                    url,
                    responseType: 'json',
                    data: formData,
                    headers: {'X-Requested-With': 'XMLHttpRequest'},
                })
                .then(() => {
                    if (currentDownloadUrl) {
                        localStorage.setItem("download-form-completed", "true");
                        closeModal(modalEl);
                        window.open(currentDownloadUrl, '_blank');
                    }
                })
                .catch((error) => {
                    formErrorEl.innerHTML = error?.response?.data?.message || error?.message;
                });
            }
        });
    }
}

export default initPressResources;
