import {utils} from "./utils";
import { Map } from  './_vendors/google-maps';
import _filter from 'lodash.filter';

let map;
let markers;
let initAutoZoomOut;

let fitBoundsTimeout;
let resetMarkersTimout;
let tempActiveMarker;

window.map_locations = window.map_locations || [];

const __addMarkers = function(tempActiveMarkerID= null, init = false){

    const _self = this;
    let bounds = new google.maps.LatLngBounds();

    let activeMarkerID = null;

    markers = map_locations.map(function(location) {
        const id = location.id;
        const title = location.title;

        let isActive = false;
        if(tempActiveMarkerID == id) {
            isActive = true;
            activeMarkerID = id;
        }

        const latlng = {
            lat: Number(location.latlng.lat),
            lng: Number(location.latlng.lng)
        };

        const marker = new google.maps.Marker({
            id: id,
            map: map,
            position: latlng,
            title: title,
            icon: {
                url: (isActive ? '/assets/images/icons/marker-on.png' : '/assets/images/icons/marker-off.png'),
                size: new google.maps.Size(34, 42),
                scaledSize: new google.maps.Size(34, 42),
                anchor: new google.maps.Point(0, 42)
            }
        });

        bounds.extend(marker.position);

        // click event
        // TODO: fix this!!
        google.maps.event.addListener(marker, 'click', function () {
            let locationElement = document.querySelector('.col__locations [data-location-id="' + this.id + '"]');
            locationElement.querySelector('button').setAttribute('aria-expanded', 'true');
            locationElement.querySelector('.location-item__description').setAttribute('aria-hidden', 'false');

            let headerOffset = 130;
            if(utils.getBreakpointDown('lg')){
                headerOffset = 90;
            }

            // highlight marker in map
            __activeMarker(this.id, 0);
            __setActiveInList(this.id);

            // const elementPosition = locationElement.offsetTop;
            const elementPosition = utils.offset(locationElement).top;
            const offsetPosition = elementPosition - headerOffset;

            // if(!utils.getBreakpointDown('lg')){
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            // }

        });

        return marker;

    });

    if(init){

        map.fitBounds(bounds);

        if(map_locations.length === 1) {
            const listener = google.maps.event.addListener(map, "idle", function () {
                if (map.getZoom() > 17) map.setZoom(17);
                google.maps.event.removeListener(listener);
            });
        }

    }

},

__toggleMap = function(e){

    const pageEl = document.querySelector('body');

    if(pageEl.classList.contains('map-is--active')){
        pageEl.classList.remove('map-is--active');
    }
    else {
        pageEl.classList.add('map-is--active');
    }

},

__setActiveInList = function(id){
    // highlight location in list
    const allElements = document.querySelectorAll('.col__locations .location-item[data-location-id]');
    const locationElement = document.querySelector('.col__locations [data-location-id = "' + id + '"]');

    const trigger = locationElement.querySelector('button');
    const description = locationElement.querySelector('.location-item__description');
    const isAlreadyExpanded = trigger.hasAttribute('aria-expanded') ? trigger.getAttribute('aria-expanded') === 'true' : false;

    // remove active el
    allElements.forEach(el => {
        el.classList.remove('is-active');
        el.querySelector('button').setAttribute('aria-expanded', 'false');
        el.querySelector('.location-item__description').setAttribute('aria-hidden', 'true');
    });

    if (!isAlreadyExpanded) {
        locationElement.classList.add('is-active');
        trigger.setAttribute('aria-expanded', 'true');
        description.setAttribute('aria-hidden', 'false');
    }
},

__activeMarker = function(activeMarkerID= null, timeout= 400, setZoom=false){

    if(resetMarkersTimout){
        clearTimeout(resetMarkersTimout)
    }

    if(tempActiveMarker != activeMarkerID){
        resetMarkersTimout = setTimeout(function(){

            // remove all markers
            markers = markers.map(function(marker) {
                marker.setMap(null);
            });

            tempActiveMarker = activeMarkerID;
            __addMarkers(activeMarkerID);

            // center map
            __centerMap(activeMarkerID);

        },timeout);
    }

    __setActiveInList(activeMarkerID);
},

__centerMap = function(activeID, setZoom=false){

    if(activeID){

        const location = _filter(map_locations, { 'id': Number(activeID) });
        if(location.length > 0){
            const latlng = location[0].latlng;

            const center = new google.maps.LatLng(latlng.lat, latlng.lng);
            map.panTo(center);
            // if(setZoom){
            //     map.setZoom(14);
            // }
        }
    }

},

__resetMarkers = function(){
    __activeMarker(null);
};

window.setActiveMarker = __activeMarker;
window.offMapMarker = __resetMarkers;
window.toggleMap = __toggleMap;

$(function() {

    // init location map
    // *************************************

    let mapElement = document.getElementById('location-map');
    if(mapElement){
        Map.loadGoogleMapsApi().then(function (googleMaps) {

            map = Map.createMap(googleMaps, mapElement);

            // set all markers
            __addMarkers(null, true);

            const activeEl = document.querySelector('.list-group-item.is-active');
            const activeWasteDepotEl = document.querySelector('.list-group-item.is-waste-depot');

            // set active wasteDepot
            if(activeWasteDepotEl && !activeEl){
                const parentEl = activeWasteDepotEl.closest('[data-location-id]');
                __activeMarker(parentEl.dataset.locationId, 400, false);
            }
            else if(activeEl){
                const parentEl = activeEl.closest('[data-location-id]');
                __activeMarker(parentEl.dataset.locationId, 400, false);
            }

            // event map on init and move
            google.maps.event.addListener(map, 'idle', function() {
                // __getVisibleMarkers();
            });

        });
    }


});

