import 'bootstrap/js/dist/modal';
import Cookies from 'js-cookie'
import { townSelector } from '../town-selector.js';

import { gtm } from '../gtm.js';

export const modals = {
    init: function () {
        this.initPostalCodeAndTownModal();

    },



    initPostalCodeAndTownModal: function (){

        const _self = this;

        _self.autoModalTrigger('town-modal-selector');
        // <div data-trigger-modal="town-modal-selector"></div>

        // on close modal
        $('#town-modal-selector').on('hidden.bs.modal', function (event) {
            if(Cookies.get('postalcode') == undefined || Cookies.get('postalcode') == '') {
                Cookies.set('postalcode', '-1',  { expires: 365 });

                gtm.push({
                    'category' : 'town-modal-selector',
                    'action' : 'click',
                    'label' : 'close',
                });

            }
        })

        // on show modal
        .on('shown.bs.modal', function (e) {
            // set close button
            if(Cookies.get('postalcode') == undefined) {
                $('#town-modal-selector .modal--skip').removeClass('d-none').addClass('d-inline-block');
                $('#town-modal-selector .modal--close').removeClass('d-inline-block').addClass('d-none');
            }
            else {
                $('#town-modal-selector .modal--close').removeClass('d-none').addClass('d-inline-block');
                $('#town-modal-selector .modal--skip').removeClass('d-inline-block').addClass('d-none');
            }

            townSelector.initPostalCodeInput('#town-modal-selector');

            gtm.push({
                'category' : 'town-modal-selector',
                'action' : 'click',
                'label' : 'open',
            });

            // autofocus
            // $('#postalcode-input').pincodeInput().data('plugin_pincodeInput').clear();
            // $('#postalcode-input').pincodeInput().data('plugin_pincodeInput').focus();

        });

        // auto open model
        if((Cookies.get('postalcode') == undefined || Cookies.get('postalcode') == '') && _self.isHomepage()) {
            _self.showPostalCodeAndTownModal();
        }

        // goto tab
        $(document).on('click','#town-modal-selector [data-goto-tab]', function(e) {
            e.preventDefault();
            _self.goToTab(this.dataset.gotoTab);
        });

        // goto-municipality in alert
        $(document).on('click','#town-modal-selector .goto-municipality', function(e) {
            e.preventDefault();
            _self.goToTab(2);
        });

        // set postalcode by submit
        $(document).on('click','#town-modal-selector .btn-selector', function(e) {
            e.preventDefault();

            let postalcode = $('#town-modal-selector #postalcode-input').val();
            postalcode = postalcode.toUpperCase();

            if(postalcode === ''){
                postalcode = '-1';
            }

            townSelector.setPostalCodeCookie(postalcode, { 'category' : 'modal' });

        });

        // set postalcode by link
        $(document).on('click','#town-modal-selector a[data-municipality]', function(e) {
            e.preventDefault();

            let municipality = this.dataset.municipality;

            townSelector.setMunicipalityCookie(municipality, { 'category' : 'modal' });

        });


    },

    showPostalCodeAndTownModal: function () {
        $('#town-modal-selector').modal('show');
    },

    isHomepage: function(){
      if($('#town-modal-selector').attr('data-modal-page') == '' || $('#town-modal-selector').attr('/')){
          return true;
      }
      return false;

    },

    autoModalTrigger: function(id){
        $(function() {
            if ($(`[data-trigger-modal="${id}"]`).length) {
                if(Cookies.get('postalcode') == undefined || Cookies.get('postalcode') == '' || Cookies.get('municipality') == '0'){
                    $(`#${id}`).modal('show');
                }
            }
        });
    },

    goToTab: function (tabIndex) {
        const tabNumber = tabIndex - 1;
        const tabTotal = 2;
        const tabPercent = 0 - tabNumber * (100 / tabTotal);
        const tabEl = document.querySelector(`[data-tab-index='${tabIndex}']`);
        const currentActiveTab = document.querySelector(`.tab.is-active[data-tab-index]`);

        // Let value correspond with transition time in CSS
        const delay = window.matchMedia('(prefers-reduced-motion: no-preference)').matches ? 700 : 50;

        $('#town-modal-selector .switch-area').css('transform',`translateX(${tabPercent}%)`);

        if (currentActiveTab) {
            setTimeout(() => {
                currentActiveTab.classList.remove('is-active');
            }, delay);
        }

        if (tabEl) {
            const firstFocusableEl = tabEl.querySelector('a, .form-control');
            tabEl.classList.add('is-active');

            console.log(firstFocusableEl);

            if (firstFocusableEl) {
                setTimeout(() => {
                    firstFocusableEl.focus();
                }, delay);
            }
        }
    },

};
