export const table = {
    init: function () {
        this.tableResponsiveChecker()
    },
    tableResponsiveChecker: function(){

        $('table').each(function() {
            const tableWidth = $(this).outerWidth();
            const containerWidth = $(this).parent().width();
            // console.log(tableWidth, containerWidth);
            if (tableWidth > containerWidth) {
                $(this).wrap( "<div class='table-container'></div>" );
                $(this).addClass('table--scrollable');
            }
        });

    }
};
