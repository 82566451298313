export const wasteSeperation = {
    init: () => {
        function getCookie(name) {
            return (document.cookie.match('(^|;) *'+name+'=([^;]*)')||[])[2];
        }

        const blockEls = document.querySelectorAll('.waste-seperation');
        const selectedMunicipality = getCookie('municipality');

        blockEls.forEach(el => {
            const municipalityIds = el.hasAttribute('data-show-only-for-municipalities') ? el.getAttribute('data-show-only-for-municipalities').split(',').filter((value) =>  value) : [];

            console.log(municipalityIds);
            console.log(municipalityIds.length);

            if (municipalityIds.length) {
                if (!municipalityIds.includes(selectedMunicipality) || !selectedMunicipality) {
                    el.remove();
                }
            }
        });
    },
};
