import Swiper from 'swiper';
import 'swiper/css';

import 'slick-carousel/slick/slick';

export const sliders = {
    init: function(){
      this.gallery();
      this.news();
      this.tips();
      this.employee();
    },
    news: function () {
        if (window.innerWidth < 768 && $('#news-slider').length) {
            $('#news-slider').slick({
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
            });
        }
    },
    tips: function () {
        if (window.innerWidth < 768 && $('#tips-slider').length) {
            $('#tips-slider').slick({
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
            });
        }
    },
    destroyNews: function(){
        if ($('#news-slider').hasClass('slick-initialized')) {
          $('#news-slider').slick('unslick');
        }
    },
    destroyTips: function(){
        if ($('#tips-slider').hasClass('slick-initialized')) {
            $('#tips-slider').slick('unslick');
        }
    },
    employee: function(){
        if ($('.employee-slider').length) {
            $('.employee-slider').each(function() {
                const container = $( this ).parent().parent();
                $( this ).slick({
                    autoplay: true,
                    variableWidth: true,
                    swipeToSlide: true,
                    speed: 200,
                    prevArrow: container.find('.custom-slick-controls a.prev'),
                    nextArrow: container.find('.custom-slick-controls a.next'),
                });

                var total = $( this ).slick("getSlick").slideCount;
                container.find('.custom-slick-controls span.total-slides').html(total);

                // On swipe event
                $( this ).on('afterChange', function (event, slick) {
                    var current = slick.currentSlide + 1;
                    if (current >= total) {
                        current = total;
                    }
                    container.find('.custom-slick-controls span.current-slide').html(current);
                });

            });

        }
    },
    gallery: function () {
        const galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });
        const galleryTop = new Swiper('.gallery-top', {
            spaceBetween: 10,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            thumbs: {
              swiper: galleryThumbs
            }
        });
    },
};
