export const jumpto = {
    init: function () {
        const offset = $('#header').outerHeight();
        $('#skiplinks a[href^="#"], #main a[href^="#"]').click(function() {
            const href = $(this).attr('href');
            $('html, body').animate({
                scrollTop: ($(href).offset().top - offset)
            }, 2000);
        })

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const scrollTo = urlParams.get('scrollTo');
        if (scrollTo) {
            $('html, body').animate({
                scrollTop: ($('#' + scrollTo).offset().top - offset)
            }, 2000);
        }
    },
};
