function togglePlacementAddress () {
    if ($('#different-placement').is(':checked')) {
        $('#placement-address').show();
        $('#placement-address input').attr('required', true);
    } else {
        $('#placement-address').hide();
        $('#placement-address input').attr('required', false);
    }
}

export const checkout = {
    init: function () {
        $(document).ready(function() {
            $('#different-placement').click(function(ev) {
                togglePlacementAddress();
            });
            togglePlacementAddress();
        });
    },
};
