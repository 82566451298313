import _debounce from 'lodash.debounce';

const breakpointSizes = {
    xxs: 0,
    xs: 370,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

export const utils = {

    init() {
        window.dataLayer = window.dataLayer || [];
        this.pageIsTouch();
    },
    isJS(html){
        document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/, 'js');
    },
    pageIsTouch(){
        if(this.detectTouchDevice){
            document.documentElement.classList.add("touch");
        }
    },
    pageIsLoaded(){
        document.documentElement.classList.add("loaded");
    },
    isIE(){
        const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
        const msie = ua.indexOf('MSIE '); // IE 10 or older
        const trident = ua.indexOf('Trident/'); //IE 11
        return (msie > 0 || trident > 0);
    },

    getAllPadding(elem){
        //Use parseInt method to get only number
        return parseInt(window.getComputedStyle(elem, null).getPropertyValue('padding-top'))
            + parseInt(window.getComputedStyle(elem, null).getPropertyValue('padding-bottom'));
    },

    getGutter(elem){
        //Use parseInt method to get only number
        return parseInt(window.getComputedStyle(elem, null).getPropertyValue('padding-left'))
            + parseInt(window.getComputedStyle(elem, null).getPropertyValue('padding-right'));
    },

    detectTouchDevice(){
        const deviceAgent = navigator.userAgent.toLowerCase();

        const isTouchDevice = ('ontouchstart' in window) ||
            (deviceAgent.match(/(iphone|ipod|ipad)/) ||
                deviceAgent.match(/(android)/)  ||
                deviceAgent.match(/(iemobile)/) ||
                deviceAgent.match(/iphone/i) ||
                deviceAgent.match(/ipad/i) ||
                deviceAgent.match(/ipod/i) ||
                deviceAgent.match(/blackberry/i) ||
                deviceAgent.match(/bada/i));

        if(isTouchDevice){
            return true;
        }

       return false;
    },

    offset(el) {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    },

    getWindowH: function () {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    },

    getBreakpoint: function (size) {
        if(size){
            return breakpointSizes[size];
        }
        return false
    },
    getBreakpointDown: function (size) {
        if(size && (this.getWindowW() < breakpointSizes[size])){
            return true;
        }
        return false
    },

    getWindowW: function () {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    },

    calcViewportHeight() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit

        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    calcViewportWidth() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit

        let vw = document.documentElement.clientWidth * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vw', `${vw}px`);
    },
    calcHeaderHeight(notification = true){

        let notificationHeight = 0;
        if(notification){
            notificationHeight = (document.querySelector("#notifications") ?  document.querySelector("#notifications").clientHeight : 0);
        }

        let topBorderHeight = 4;

        const topBarHeight = document.querySelector("#topbar").clientHeight + (utils.getBreakpointDown('lg') ? 0 : topBorderHeight) + 1;
        const headerHeight = document.querySelector("#header").clientHeight;

        const headerH = notificationHeight + topBarHeight + headerHeight;

        document.documentElement.style.setProperty('--headerh', `${headerH}px`);

    },

    scrollTo(){
        const links = document.querySelectorAll(".scrollTo");

        for (const link of links) {
            link.addEventListener("click", clickHandler);
        }

        function clickHandler(e) {
            e.preventDefault();
            const href = this.getAttribute("href");
            const offsetTop = document.querySelector(href).offsetTop;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    },

    tooltip(){
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
    },

    // externalLink(){
    //
    //     const { subDomains, domain, topLevelDomains } = parseDomain(window.location.hostname);
    //     const hostName = domain;
    //     const selector = 'a:not([href*="' + hostName + '"]):not([href^="#"]):not([href^="/"]):not([href^="?"]):not([href^="mailto:"]):not([href^="tel:"]):not([href=""]):not([href^="javascript:"])'; // internal url's must alwayws started with /
    //
    //     // selector
    //
    //     const matches = document.querySelectorAll(selector);
    //     if(matches.length > 0){
    //
    //         matches.forEach(element => {
    //             element.classList.add("is-external-link");
    //         });
    //
    //     }
    //
    // },

    resize(){

        const self = this;
        window.addEventListener('resize', _debounce(() => {
            self.calcViewportHeight();
            self.calcViewportWidth();
            self.calcHeaderHeight();
        }, 400));

    }

};
