"use strict";

import "core-js/stable"
import "regenerator-runtime/runtime"
import 'babel-polyfill';

import "./app.scss"

window.dataLayer = window.dataLayer || [];

let resizeTimeout = false;

import { utils } from './js/utils';
import { navigation } from './js/navigation';
import { notification } from './js/notification';
import { jumpto } from './js/jumpto';
import { tooltip } from './js/tooltip';
import { submenu } from './js/submenu';
import { productPage } from './js/productpage';
import { dateInput } from './js/dateinput';
import { sliders } from './js/sliders';
import { checkout } from './js/checkout';
import { swipemenu } from './js/swipemenu';
import { townSelector } from './js/town-selector';
import { aswAutocomplete } from './js/asw-autocomplete';
import { dynamicCounter } from './js/dynamic-counter';
import { scrollTo } from './js/scroll-to';
import { confetti } from './js/confetti';
import { searchAutocomplete } from './js/search-autocomplete';
import { table } from './js/table';
import { filter } from './js/filter';
import { form } from './js/form';
import { wasteSeperation } from './js/waste-seperation';
import initPressResources from './js/press-resources';
import './js/locationMap.js';

import './js/_vendors/jquery.raptorize.1.0.js';
import { raptorize } from './js/raptorize';

// bootstrap
import 'bootstrap/js/dist/collapse';
import { modals } from './js/_bootstrap/modals.js';

$(function() {

  $('html').removeClass('no-js');

  setTimeout(function() {
    $('html').addClass('loaded');
  }, 300);

  utils.init();
  navigation.init();
  notification.init();
  jumpto.init();
  submenu.init();
  sliders.init();
  checkout.init();
  productPage.init();
  tooltip.init();
  dateInput.init();
  townSelector.init();
  modals.init();
  swipemenu.init();
  aswAutocomplete.init();
  table.init();
  raptorize.init();
  dynamicCounter.init();
  scrollTo.init();
  searchAutocomplete.init();
  filter.init();
  wasteSeperation.init();
  form.init();
  initPressResources();

  if (document.querySelector("#main.homepage.homepage--anniversary")) {
    confetti.init();
  }

  // window event handlers
  // *************************************
  $(window).resize(function() {

    if(resizeTimeout){
      clearTimeout(resizeTimeout);
    }

    resizeTimeout = setTimeout(function(){
      $('ul.main-menu div.submenu').css({'height': 'auto', 'overflow-y': 'visible'});
      if (window.outerWidth > 768 ) {
        sliders.destroyNews();
        sliders.destroyTips();
      }
      else {
        sliders.news();
        sliders.tips();
      }
    },100);

  });

});

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
