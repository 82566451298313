export const gtm = {
    push: function (options) {
        var data = {
            'event' : 'rd4-corporate',
            'eventCategory' : (options.category == undefined ? '' : options.category),
            'eventAction' : (options.action == undefined ? '' : options.action),
            'eventLabel' :  (options.label == undefined ? '' : options.label),
            'eventResults' :  (options.results == undefined ? '' : options.results),
            'eventValue' : (options.value == undefined ? '' : options.value)
        };

        // use speakingurl (npmjs.com/package/speakingurl) to convert labels to a url slug
        // author Sascha Droste

        window.dataLayer.push(data);
    }
};
