import JSConfetti from "js-confetti";

export const confetti = {
  init: () => {
    if (new Date() > new Date("2023-12-31")) {
      return;
    }

    const jsConfetti = new JSConfetti();

    document.addEventListener(
      "scroll",
      () => {
        if (
          window.scrollY > 5 &&
          sessionStorage.getItem("confettiIsShown") !== "true"
        ) {
          jsConfetti.addConfetti({
            confettiColors: [
              "#F9F91D",
              "#019c90",
              "#91c957",
              "#114dc9",
              "#456FA0",
	            "#E2173A",
	            "#94DD36",
	            "#AA18B5",
	            "#FF9100",
            ],
          });
          sessionStorage.setItem("confettiIsShown", "true");
        }
      },
      false
    );
  },
};
