import 'element-closest-polyfill';
import 'bootstrap/js/dist/modal';
import Cookies from 'js-cookie'

import './_vendors/bootstrap-pincode-input';
import { gtm } from './gtm.js';

export const townSelector = {
    init: function () {
        this.initSelector();
        this.initWebRing();
    },

    validateMunicipality: function(el){

        const municipalitySelect = $(el).find('#municipality-select');
        if(municipalitySelect){

            let validated = false;
            let municipality = $('#town-selector #municipality-select').val();

            if(municipality > 0){
                validated = true;
            }

            if(validated){
                $(el).find('.btn-selector').removeAttr('disabled');
            }
            else {
                $(el).find('.btn-selector').attr('disabled', true);
            }

            return validated;

        }

    },

    validPostalCode: function (el){
        let val;
        let validated = true;
        let hasValidationError = false

        $(el).find('.postalcode-selector .pincode-input-text').each(function(index) {

            index = index + 1;

            val = $(this).val();

            $(this).removeClass('is-wrong');
            let fieldValid = true;

            if(val != ''){

                let num = isNaN(parseInt(val)) ? false : parseInt(val)

                if(index <= 4){
                    // First four fields (should be a number)
                    if(val == 0){}
                    else if (!num) {
                        validated = false;
                        fieldValid = false;
                    }
                }
                else {
                    // First four fields (should be a letter)
                    if (num) {
                        validated = false;
                        fieldValid = false;
                    }
                }
            }
            else {
                validated = false;
            }

            if(!fieldValid){
                $(this).addClass('is-wrong');
                hasValidationError = true;
            }
        });

        if(validated){
            $(el).find('.btn-selector').removeAttr('disabled');
        }
        else {
            $(el).find('.btn-selector').attr('disabled', true);
        }

        if (hasValidationError) {
            $(el).find('.postal-code-validation-failed').removeClass('d-none');
        } else {
            $(el).find('.postal-code-validation-failed').addClass('d-none');
        }

        return validated;

    },

    resetPostalCodeInput: function(el){
        $(el).find('.postalcode-selector .pincode-input-text').val('');
        $(el).find('#postalcode-input').val('');
    },

    initPostalCodeInput: function(el){

        const _self = this;

        // init pincode
        // Use input type `tel` so a letter can be inserted,
        // but the validation will fail (since it’s not a number)
        $(el).find('#postalcode-input').pincodeInput({
            inputs: 6,
            placeholders: '1 2 3 4 A A',
            inputtypes: 'tel tel tel tel text text',
            pattern: '[0-9a-zA-Z]*',
            inputtype: 'text',
            inputmode: 'text',
            change: function(input, value, inputnumber){
                _self.validPostalCode(el)
            },
            complete: function (text, evt) {
                console.log(evt);
                _self.validPostalCode(el)
            }
        });

        const postalCodeContainer = document.querySelector('.pincode-input-container');

        if (postalCodeContainer) {
            const postalCodeFields = postalCodeContainer.querySelectorAll('.pincode-input-text');

            postalCodeFields.forEach((inputEl, index) => {
                const position = index + 1;
                const labelEl = document.createElement('label');
                const inputId = `postal-code-${position}`;
                let labelText = '';

                if (position <= 4) {
                    labelText = `Postcode cijfer ${position}`;
                } else if (position == 5) {
                    labelText = `Postcode letter 1`;
                } else if (position == 6) {
                    labelText = `Postcode letter 2`;
                }

                labelEl.setAttribute('for', inputId);
                labelEl.innerHTML = labelText;
                labelEl.classList.add('sr-only');

                inputEl.setAttribute('id', inputId);

                if (labelText) {
                    postalCodeContainer.insertBefore(labelEl, inputEl);
                }
            });
        }
    },

    initSelector: function (){

        const _self = this;
        _self.initPostalCodeInput('#town-selector');


        // set postalcode by submit
        $(document).on('click','#town-selector .btn-selector', function(e) {
            e.preventDefault();

            let postalcode = $('#town-selector #postalcode-input').val();
            let municipality = $('#town-selector #municipality-select').val();
            postalcode = postalcode.toUpperCase();

            if(municipality > 0){
                _self.setMunicipalityCookie(municipality);
            }
            else {
                if(postalcode === ''){
                    postalcode = '-1';
                }

                _self.setPostalCodeCookie(postalcode);
            }
        });

        // goto-municipality in alert
        $(document).on('click','#town-selector .goto-municipality', function(e) {
            e.preventDefault();
            $('#town-selector #municipality-select').focus();
        });

        // set municipality by select
        $(document).on('change','#town-selector #municipality-select', function(e) {
            e.preventDefault();
            _self.resetPostalCodeInput('#town-selector');
            _self.validateMunicipality('#town-selector');
        });

    },

    initWebRing: function(){

        const _self = this;

        // set municipality by select
        $(document).on('change','#webring .town-selector select', function(e) {
            e.preventDefault();
            const municipality = $(this).val();
            _self.setMunicipalityCookie(municipality, { 'category': 'webring' })
        });

    },

    setPostalCodeCookie: function(postalcode, options){
        Cookies.set('postalcode', postalcode, { expires: 365 });
        this.setMunicipalityByPostalCode(postalcode, options);
    },

    setMunicipalityCookie: function(municipality, options = false){
        Cookies.set('municipality', municipality, { expires: 365 });

        let postalcode = '-1';
        Cookies.set('postalcode', postalcode, { expires: 365 });

        let categoryName = 'inline';

        if(options && options.hasOwnProperty('category')){
            categoryName =  options.category;
        }

        gtm.push({
            'category' : `town-${categoryName}-selector`,
            'action' : 'save',
            'label' : 'municipality',
        });

        window.location.reload(true);

    },

    setMunicipalityByPostalCode: function(postalcode, options = false) {

        $.ajax({
            type: "POST",
            url: "/ajax/municipality/lookup",
            data: JSON.stringify({"postalCode": postalcode}),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function(data) {

                let categoryName = 'inline';
                if(options && options.hasOwnProperty('category')){
                    categoryName =  options.category;
                }

                if(data.municipality == 0){

                    let el = '#town-selector';
                    if(categoryName == 'modal'){
                        el = '#town-modal-selector';
                    }

                    // show alert
                    $(el).find('.result-output').html('<p class="alert-wrong-postalcode">De ingevoerde postcode valt buiten ons gebied. Probeer een andere postcode of kies een <a href="#" class="goto-municipality">gemeente.</a></p>');

                    gtm.push({
                        'category' : `town-${categoryName}-selector`,
                        'action' : 'wrong',
                        'label' : 'postalcode',
                    });

                }
                else {

                    // set municipality cookie
                    Cookies.set('municipality', data.municipality, { expires: 365 });

                    gtm.push({
                        'category' : `town-${categoryName}-selector`,
                        'action' : 'save',
                        'label' : 'postalcode',
                    });

                    window.location.reload(true);
                }


            },
            failure: function(errMsg) {
                return 0;
            }
        });
    }

};
