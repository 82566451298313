import _debounce from 'lodash.debounce';
import getSlug from 'speakingurl';

import { utils } from './utils';
import {gtm} from "./gtm";

const aswContainer = document.querySelector('#asw-autocomplete-container');

let hideResultTimeout;
let intervalTimer;
export const aswAutocomplete = {
    init: function () {

        const _self = this;

        if(aswContainer){

            // keyup - search
            aswContainer.querySelector('.field-autocomplete').addEventListener('keyup', _debounce(() => {
                _self.runSearch();
            }, 400));

            // click - search
            aswContainer.querySelector('.field-autocomplete').addEventListener('click', _debounce(() => {
                _self.runSearch();
            }, 100));

            // mouseover - results
            aswContainer.querySelector('.results-autocomplete').addEventListener('mouseover', function(){
                if(hideResultTimeout){
                    hideResultTimeout.cancel();
                }
            });

            // mousover - input
            aswContainer.querySelector('.field-autocomplete').addEventListener('mouseover', function(){
                if(hideResultTimeout){
                    hideResultTimeout.cancel();
                }
            });

            // mouseout - container
            aswContainer.addEventListener('mouseout', function(){
                _self.hideResults(400);
            });

            // enter submit
            aswContainer.querySelector('form').addEventListener('submit', function(e){
                e.preventDefault();

                clearInterval(intervalTimer);

                intervalTimer = setInterval ( function(){
                    _self.goToFirstResult();
                }, 100 );

            });

        }

    },

    goToFirstResult: function(){
        const resultsEl = aswContainer.querySelector('.results-autocomplete');

        const firstResult = resultsEl.querySelectorAll('ul li')[0];
        const href = firstResult.querySelector('a').getAttribute("href");
        if(href){
            window.location.href = href;
            clearInterval(intervalTimer);
        }

    },

    showResults: function(){
        aswContainer.classList.add('is-visible');
    },

    hideResults: function(timeout = 1000){

        clearInterval(intervalTimer);
        hideResultTimeout = _debounce(() => {

            if(aswContainer.classList.contains('is-visible')){
                aswContainer.classList.remove('is-visible');
            }

        }, timeout);

        hideResultTimeout();

    },
    noResults: function(){
        return '<p>Er zijn geen overeenkomsten gevonden!</p>'
    },

    runSearch: function(){
        clearInterval(intervalTimer);
        this.getResults();
        if(hideResultTimeout){
            hideResultTimeout.cancel();
        }
    },

    getResults: function(){

        const _self = this;

        let searchValue = aswContainer.querySelector('.field-autocomplete').value;
        const resultsEl = aswContainer.querySelector('.results-autocomplete');

        if(searchValue.length > 0){
            $.ajax({
                type: "GET",
                url: "/ajax/products/search",
                data: { q: searchValue },
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function(results) {

                    let htmlOutput = '';

                    gtm.push({
                        'category' : 'asw-autocomplete',
                        'action' : getSlug(searchValue),
                        'label' : results.length
                    });

                    // print results
                    results.forEach(result => {
                        htmlOutput += '<li><a href="' + result.url + '">' + result.title + '</a></li>';
                    });

                    resultsEl.innerHTML = ( results.length > 0 ? '<ul>' + htmlOutput  + '</ul>' : _self.noResults() );

                    _self.showResults();

                },
                failure: function(errMsg) {
                    return 0;
                }
            });
        }

    }

};




