const loadGoogleMapsApi = require('load-google-maps-api');

class Map {

    static loadGoogleMapsApi() {
        // return loadGoogleMapsApi({ key: process.env.GOOGLEMAPS_KEY });
        return loadGoogleMapsApi({ key: 'AIzaSyDr_OzsGKGzdsB2cO4FR34Cfv5NUvR9PU4' });
    }
    static createMap(googleMaps, mapElement) {
        return new googleMaps.Map(mapElement, {
            center: { lat: 50.88365, lng: 5.98154 }, // Eindhoven as start
            zoom: 11,
            maxZoom: 17,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
        });
    }
}
export { Map };
