import Cookies from 'js-cookie'

export const notification = {
    init: function () {
        $('#notifications .notification').each(function() {
            const uuid = $(this).attr('data-uuid');
            if(Cookies.get(uuid) == undefined || Cookies.get(uuid) == '0') {
                $(this).addClass('is-active');
            }
        });
        $('#notifications .notification a.close-notification').on('click', function() {
            const element = $(this).closest('.notification');
            const uuid = $(this).closest('.notification').attr('data-uuid');
            Cookies.set(uuid, '1', { expires: 30, path: '/' })
            element.removeClass('is-active');
        });
    },
};
