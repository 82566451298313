export const dynamicCounter = {
    init: function () {
        if ($('.section--dynamic_counters').length) {
            $('div.statistic p.value').each(function (index) {
                let done = false;
                var element = $(this);
                element.attr('id', ('customId' + index));
                var value = $(this).attr('data-val');
                var strippedValue = value.split('.').join("");
                const options = {
                    separator: '.',
                };
                var controller = new ScrollMagic.Controller();
                new ScrollMagic.Scene({
                    triggerElement: this,
                    triggerHook: 0.9
                })
                    .on('start', function () {
                        if (!done) {
                            const c = new countUp.CountUp(('customId' + index), strippedValue, options)
                            c.start()
                            done = true;
                        }
                    })
                    // .addIndicators()
                    .addTo(controller);
            });
        }
    },
};
