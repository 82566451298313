import './_vendors/autocomplete/jquery.autocomplete.custom.js';
import {gtm} from "./gtm";
import getSlug from "speakingurl";
import _debounce from "lodash.debounce";

window.dataLayer = window.dataLayer || [];

export const searchAutocomplete = {
    init: function () {

        const inputFieldEl          = ".input-autocomplete";
        const formEl                = ".form-autocomplete";

        const __triggerSubmit = function(e){

            setTimeout(() => {

                gtm.push({
                    'category' : 'search-autocomplete',
                    'action' : 'show-all',
                });

                const inputFieldEl = $(".input-autocomplete");
                inputFieldEl.closest("form").submit();
            }, 100);

            e.preventDefault();

        }

        window.triggerAutoCompleteSubmit = __triggerSubmit;

        // https://github.com/devbridge/jQuery-Autocomplete
        if($(inputFieldEl)){
            $(inputFieldEl).autocomplete({
                serviceUrl: "/api/v1/search",
                // appendTo: formEl,
                maxHeight: false,
                width: 'auto',
                zIndex: 1,
                paramName: "q",
                minChars: 1,
                autoSelectFirst: false,
                triggerSelectOnValidInput: false,
                showNoSuggestionNotice: true,
                noSuggestionNotice: "Er zijn geen overeenkomsten gevonden!",
                deferRequestBy: 100,
                beforeRender: function(container, suggestions) {
                    $(formEl).addClass("autocomplete-is-active");
                    $("body").addClass("autocomplete-overlay");

                    if(suggestions.length > 0){
                        gtm.push({
                            'category' : 'search-autocomplete',
                            'action' : getSlug($(inputFieldEl).val()),
                            'label' : suggestions.length
                        });
                    }

                    if(suggestions.length >= 1){
                        container.prepend($("<h3>Suggesties:</h3>"));
                    }

                    if(suggestions.length >= 10){
                        $("<div class='autocomplete-footer'><a href='javacsript:void(0);' class='btn btn-link mt-auto mr-auto' onclick='triggerAutoCompleteSubmit()'>Toon alles</a></div>").insertAfter(container.find(".autocomplete-suggestion").last());
                    }

                },
                onSelect: function(suggestion) {
                    window.location.href = suggestion.url;
                },
                onSearchStart: function() {
                },
                onSearchComplete: function(search) {},
                onHide: function(){
                    $(formEl).removeClass("autocomplete-is-active");
                    $("body").removeClass("autocomplete-overlay");
                },
                formatGroup: function(suggestion) {
                    return `<div class="test"></div>`;
                },
                transformResult: function(response){
                    const excludeIdString   = document.querySelector("#id-selector");

                    const excludeIds        = excludeIdString.dataset.excludeid ? excludeIdString.dataset.excludeid.split(",").map(Number) : [];

                    return {
                        suggestions: $.map(JSON.parse(response).data, function(dataItem) {
                            if (!excludeIds.includes(dataItem.id)) {
                                return { value: dataItem.title, url: dataItem.url };
                            }
                        })
                    };
                }
            });
        }

    },
};

