import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import _debounce from 'lodash.debounce';
import { utils } from './utils';

let ps;
const swipeContainer = document.querySelector('.swipe-container');

export const swipemenu = {
    init: function () {

        const _self = this;

        if(swipeContainer){
            ps = new PerfectScrollbar(swipeContainer, {
                useBothWheelAxes: false,
                wheelPropagation: false,
                scrollingThreshold: 500,
            });

            // _self.drag();
            _self.scrollToTabOverview();
            // _self.renameTabTarget();

            const btns = swipeContainer.querySelectorAll('a');
            btns.forEach(btn => {
                btn.addEventListener('click', _self.saveScrollPos);
            });

            window.addEventListener('resize', _debounce(() => {
                _self.update();
                // _self.renameTabTarget();
            }, 400));

        }

    },

    renameTabTarget: function(){

        if(utils.getBreakpointDown('lg')){
            swipeContainer.id = "tab-mobile";
        }
        else {
            swipeContainer.id = "tab";
        }

    },

    scrollToTabOverview: function(){

        const _self = this;
        const hash = window.location.hash;

        _self.scrollToTabItem();

        if(hash == '#tab'){

            let scrollPos = localStorage.getItem('scrollPos');
            if(scrollPos != undefined){

                if(utils.getBreakpointDown('lg')){
                    scrollPos = 0;
                }

                window.scroll(0, scrollPos);
                localStorage.removeItem('scrollPos');
            }
        }

    },

    scrollToTabItem: function(){
        const _self = this;
        if(ps && swipeContainer.classList.contains('ps--active-x')){
            const lists = swipeContainer.querySelectorAll('li');
            let scrollPosX = 0;
            let activeElFound = false;
            lists.forEach(list => {

                let activeEl = list.querySelector('a').classList.contains('is-active');
                if(activeEl){
                    activeElFound = true;
                }

                if(!activeElFound){
                    scrollPosX += list.clientWidth;
                }

            });

            if(scrollPosX > 0){
                scrollPosX += 15;
                swipeContainer.scroll(scrollPosX, 0);
            }

        }
    },

    saveScrollPos: function(){
        const scrollY = window.scrollY
        localStorage.setItem('scrollPos', scrollY);
    },

    drag: function(){

        if(!utils.detectTouchDevice()){

        // document.addEventListener('DOMContentLoaded', function () {
            const ele = swipeContainer;
            ele.style.cursor = 'grab';

            let pos = { top: 0, left: 0, x: 0, y: 0 };

            const mouseDownHandler = function (e) {
                ele.style.cursor = 'grabbing';

                pos = {
                    left: ele.scrollLeft,
                    top: ele.scrollTop,
                    // Get the current mouse position
                    x: e.clientX,
                    y: e.clientY,
                };

                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
            };

            const mouseMoveHandler = function (e) {
                // How far the mouse has been moved
                const dx = e.clientX - pos.x;
                const dy = e.clientY - pos.y;

                // Scroll the element
                ele.scrollTop = pos.top - dy;
                ele.scrollLeft = pos.left - dx;

                ele.style.userSelect = 'none';
                ele.classList.add('is-grabbing');

            };

            const mouseUpHandler = function () {
                ele.style.cursor = 'grab';
                ele.classList.remove('is-grabbing');
                ele.style.removeProperty('user-select');

                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            };

            // Attach the handler
            ele.addEventListener('mousedown', mouseDownHandler);

        // });

        }

    },

    update: function () {

        if(ps){
            ps.update();
        }

    }

};
