import scrollToElement from "scroll-to-element";

export const scrollTo = {
    init: function(){

        var scrollToEl = document.querySelector('.scroll-to');
        var headerEl = document.querySelector('#sticky-header');

        scrollToElement(scrollToEl, {
            offset: 0 - (headerEl.offsetHeight - 1),
            ease: 'linear',
            duration: 200
        });

    },
};
