import { Validator, nlLang as nl } from "@upjs/facile-validator";

export const form = {
  init: () => {
    const formEls = document.querySelectorAll("form.wheelform");
    const requiredFormGroups = document.querySelectorAll(".form-group--required");

    const nlMesssages = { ...nl };

    nlMesssages["accepted"] = "Je bent niet akkoord gegaan met dit veld";
    nlMesssages["required"] = "Dit veld is niet ingevuld";

    formEls.forEach((el) => {
      const v = new Validator(el, {
        lang: nlMesssages,
        onFieldChangeValidation: true,
        xRules: {
          dutchPhone: {
            value:
              /^(?:\+31|0)(?:(6[\s-]?[1-9]\d{7})|(?:[1-9]\d{1,2}[\s-]?\d{6,7}))$/,
            errorMessage: "Dit is een ongeldig Nederlands telefoonnummer. Een geldig nummer is bijvoorbeeld 0612345678.",
          },
        },
      });

      el.setAttribute("novalidate", "");

      el.addEventListener("submit", (evt) => {
        evt.preventDefault();

        v.validate();
      });

      v.on("validation:success", () => {
        el.submit();
      });
    });

    const updateCheckedState = (radioCheckboxItems, initialValidationRules, lastItemEl) => {
      const checkedItems = Array.from(radioCheckboxItems).filter(item => item.checked);

      console.log(radioCheckboxItems);
      console.log(initialValidationRules);

      if (lastItemEl && checkedItems.length) {
        lastItemEl.removeAttribute('data-rules');
      } else if(lastItemEl) {
        lastItemEl.setAttribute('data-rules', initialValidationRules);
      }
    }

    // FE validation isn’t working with a group of checkbox/radios:
    // https://github.com/upjs/facile-validator/issues/339
    requiredFormGroups.forEach((el) => {
      const radioCheckboxItems = el.querySelectorAll('input[type=radio], input[type=checkbox]');

      if (radioCheckboxItems.length > 1) {
        radioCheckboxItems.forEach(radioCheckboxEl => {
            const lastItemEl = radioCheckboxItems[radioCheckboxItems.length - 1];
            const initialValidationRules = lastItemEl.hasAttribute('data-rules') ? lastItemEl.getAttribute('data-rules') : '';

            radioCheckboxEl.addEventListener('change', () => {
              updateCheckedState(radioCheckboxItems, initialValidationRules, lastItemEl);
            });

            updateCheckedState(radioCheckboxItems, initialValidationRules, lastItemEl);
        });
      }
    });
  },
};
