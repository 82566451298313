import _debounce from "lodash.debounce";

var scrollUpTimeout;
var scrollTimeout = 100;

var headerIsScrollUp = false;
var tempScrollTop = 0;
var currentScrollTop =  0;

const languageButton = document.querySelector('.language-selector__button');
const languageDropdown = document.querySelector('.language-selector__dropdown');

const handleClickOutside = (evt) => {
    if (!evt.target.closest('.language-selector__dropdown') && !evt.target.closest('.language-selector__button')) {
        if (languageDropdown.getAttribute('aria-hidden') === 'false') {
            navigation.closeLanguageDropdown();
        }
    }
}

export const navigation = {
    init: function () {

        const _self = this;

        window.addEventListener('resize', _debounce(() => {
            _self.height();
        }, 50));

        languageButton.addEventListener('click', () => {
            if (languageDropdown.getAttribute('aria-hidden') === 'true') {
                _self.openLanguageDropdown();
            } else {
                _self.closeLanguageDropdown();
            }
        });

        $(document).on('click', function(event) {
            const target = $(event.target);

            _self.height();

            if (!$('body').hasClass('show-mobile-menu')) {
                if (target.closest('#hamburger').length) {
                    $('body').addClass('show-mobile-menu');
                    $('#mobile-navigation').attr('aria-hidden', 'false')
                    $('#hamburger').attr('aria-expanded', 'true')
                    window.localStorage.setItem('submenu', undefined);

                    const footerMenu = $('#menu-container').find('.mobile-footer-menu');
                    footerMenu.addClass('is-active');

                }
            }
            else if ($('body').hasClass('show-mobile-menu')) {
                if(target.closest('#header #hamburger').length || target.closest('#header .menu-controls .close-menu').length || ((!target.closest('#header').length && !target.closest('.submenu .wrapper').length))) {
                    $('body').removeClass('show-mobile-menu');
                    $('#mobile-navigation').attr('aria-hidden', 'true')
                    $('#hamburger').attr('aria-expanded', 'false')
                    $('ul.main-menu li.has-children > .toggle-subnav').removeClass('active');
                    $('ul.main-menu li.has-children > .toggle-subnav').attr('aria-expanded', 'false')
                    $('[data-submenu]').removeClass('show');
                    $('[data-submenu]').attr('aria-hidden', 'true');
                    if (window.outerWidth > 990) {
                        $('ul.main-menu li.has-children > a').removeClass('active');
                    }
                }
            }
        });

        $(document).keydown(function(e) {
            const key = e;
            if (e.keyCode == 27) {
                $('body').removeClass('show-mobile-menu');
                $('#mobile-navigation').attr('aria-hidden', 'true')
                $('#hamburger').attr('aria-expanded', 'false')
            }
        });

        document.addEventListener('scroll', function(e) {
            _self.stickyHeader();
        }, false);

    },
    openLanguageDropdown: () => {
        languageButton.setAttribute('aria-expanded', true);
        languageDropdown.setAttribute('aria-hidden', false);

        document.addEventListener("click", handleClickOutside, true);
    },
    closeLanguageDropdown: () => {
        languageButton.setAttribute('aria-expanded', false);
        languageDropdown.setAttribute('aria-hidden', true);

        document.removeEventListener("click", handleClickOutside, true);
    },
    height: function () {

        $('#menu-container').removeAttr('style');
        $('ul.main-menu div.submenu > div.container > div.wrapper').removeAttr('style');

        if (window.outerWidth > 768) {
            const calculatedHeight = (Math.floor(window.innerHeight - ($('#webring').outerHeight() + $('#header').outerHeight())) - 10);
            $('ul.main-menu div.submenu > div.container > div.wrapper').css({
                'max-height': calculatedHeight + 'px',
                'overflow-y': 'auto',
                'overflow-x': 'hidden'
            });

        } else {
            const calculatedHeight = (window.innerHeight - ($('#webring').outerHeight() + $('#header').outerHeight()));

            $('#menu-container').css({
                'height': calculatedHeight + 'px',
                'overflow-y': 'auto',
                'overflow-x': 'hidden'
            });

            $('ul.main-menu div.submenu > div.container > div.wrapper').css({
                'max-height': 'none',
                'overflow-y': 'visible',
                'overflow-x': 'hidden'
            });

        }
    },
    stickyHeader: function () {
        const _self = this;

        if(scrollUpTimeout){
            clearTimeout(scrollUpTimeout);
        }

        scrollUpTimeout = setTimeout(function(){
            var scrollTop = document.documentElement.scrollTop;
            var headerHeight = document.querySelector('#sticky-header').offsetHeight;

            currentScrollTop = scrollTop;

            if (tempScrollTop < currentScrollTop && scrollTop > (headerHeight * 2)) {
                headerIsScrollUp = true;
            } else if (tempScrollTop  > currentScrollTop && !(scrollTop <= headerHeight) || currentScrollTop == 0) {
                headerIsScrollUp = false;
            }
            tempScrollTop = currentScrollTop;

            if(headerIsScrollUp){
                $('#sticky-header').addClass('is-scrollup');
            }
            else {
                $('#sticky-header').removeClass('is-scrollup');
            }

            _self.closeLanguageDropdown();

        }, scrollTimeout);
    },
};
