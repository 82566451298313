import _debounce from "lodash.debounce";

export const submenu = {
    init: function () {
        const $toggleButton = $('ul.main-menu li.has-children > button.toggle-subnav');
        const submenuObj = this;

        $toggleButton.click(function(event) {
            const collapseNav = $(this).hasClass('active') && window.innerWidth > 990;

            if (collapseNav) {
                submenuObj.collapseSubNav($(this));
            } else {
                if (window.innerWidth > 990) {
                    submenuObj.collapseAllSubNav();
                }

                submenuObj.expandSubNav($(this));
            }
        });

        $(document).keyup(function(e) {
            if (e.key === "Escape") {
                submenuObj.collapseAllSubNav();
           }
       });

        $('.menu-controls .previous').click(function(event) {
            const activeSubmenu = window.localStorage.getItem('submenu');
            // console.log(activeSubmenu);
            if(activeSubmenu !== 'undefined') {
                $('[data-submenu=' + activeSubmenu + ']').prev().removeClass('show');
                $('[data-submenu=' + activeSubmenu + ']').removeClass('show');
                $('.toggle-subnav').attr('aria-expanded', 'false');
                $('[data-submenu]').attr('aria-hidden', 'true');
                const parent = $('[data-submenu='+activeSubmenu+']').parents('[data-submenu]');
                window.localStorage.setItem('submenu', parent.attr('data-submenu'));

                const footerMenu = $('#menu-container').find('.mobile-footer-menu');
                footerMenu.addClass('is-active');

            }
            else {
                $('body').removeClass('show-mobile-menu');
                $('#mobile-navigation').attr('aria-hidden', 'true')
                $('#hamburger').attr('aria-expanded', 'false')
            }
        });
    },

    collapseAllSubNav: function () {
        $('ul.main-menu li.has-children > button.toggle-subnav').removeClass('active');
        $('ul.main-menu li.has-children > button.toggle-subnav').attr('aria-expanded', 'false');
        $('ul.main-menu li.has-children').find('.submenu-title').removeClass('show');
        $('ul.main-menu li.has-children').find('[data-submenu]').removeClass('show');
        $('ul.main-menu li.has-children').find('[data-submenu]').attr('aria-hidden', 'true');
        window.localStorage.setItem('submenu', '');
    },

    collapseSubNav: function ($trigger) {
        const parent = $trigger.closest('li');
        const submenuTitle = parent.children('.submenu-title');
        const submenu = parent.children('[data-submenu]');

        $trigger.removeClass('active');
        $trigger.attr('aria-expanded', 'false');
        submenuTitle.removeClass('show');
        submenu.removeClass('show');
        submenu.attr('aria-hidden', 'true');
        $('body').removeClass('show-mobile-menu');
        $('#mobile-navigation').attr('aria-hidden', 'true')
        $('#hamburger').attr('aria-expanded', 'false')

        window.localStorage.setItem('submenu', '');
    },

    expandSubNav: function ($trigger) {
        const parent = $trigger.closest('li');
        const submenuTitle = parent.children('.submenu-title');
        const submenu = parent.children('[data-submenu]');
        const footerMenu = $('#menu-container').find('.mobile-footer-menu');

        $trigger.addClass('active');
        $trigger.attr('aria-expanded', 'true');
        submenuTitle.addClass('show');
        submenu.addClass('show');
        submenu.attr('aria-hidden', 'false');
        $('body').addClass('show-mobile-menu');
        $('#mobile-navigation').attr('aria-hidden', 'false')
        $('#hamburger').attr('aria-expanded', 'true')
        footerMenu.removeClass('is-active');

        if (submenu.attr('data-submenu')) {
            window.localStorage.setItem('submenu', submenu.attr('data-submenu'));
        }
    },
};
